/**
 * ExpandableCard
 */

import React from 'react';
import classNames from 'classnames';
import AnimateHeight from 'react-animate-height';
import s from './ExpandableCard.module.scss';

class ExpandableCard extends React.PureComponent {
    state = {
        open: false,
    };

    _handleClick = () => {
        if (!this.state.open) {
            this.props.onOpen(this.props.title);
        }
        this.setState({
            open: !this.state.open,
        });
    };

    _modifyOpen = (className) => {
        return classNames(s[className], {
            [s[`${className}--Open`]]: this.state.open,
        });
    };

    render() {
        const { title, children } = this.props;

        const baseClasses = this._modifyOpen('ExpandableCard');
        const toggleIconClasses = this._modifyOpen(
            'ExpandableCard__ToggleIcon'
        );

        return (
            <div className={baseClasses}>
                <div
                    className={s['ExpandableCard__Header']}
                    onClick={this._handleClick}>
                    <h2 className={s['ExpandableCard__Title']}>{title}</h2>
                    <div className={toggleIconClasses} />
                </div>
                <AnimateHeight
                    duration={250}
                    animateOpacity={true}
                    height={this.state.open ? 'auto' : 0}>
                    <div className={s['ExpandableCard__Content']}>{children}</div>
                </AnimateHeight>
            </div>
        );
    }
}

ExpandableCard.defaultProps = {
    onOpen: () => null,
};

export default ExpandableCard;
