/**
 * FaqList
 */

import React from 'react';
import ExpandableCard from 'Components/ExpandableCard';
import Wysiwyg from 'Components/Wysiwyg';
import { dataLayerPush } from 'utils/Datalayer';
import s from './FaqList.module.scss';

const FaqList = ({ items, tag, title }) => {
    const hasHeader = Boolean(tag || title);

    const handleFaqOpen = (question) =>  {
        dataLayerPush({
            'event': 'FAQ Click',
            'faqQuestion': question,
        });
    };

    return (
        <div className={s['FaqList']}>
            {hasHeader && (
                <div className={s['FaqList__Header']}>
                    {tag && <span className={s['FaqList__Tag']}>{tag}</span>}
                    {title && <h2 className={s['FaqList__Title']}>{title}</h2>}
                </div>
            )}

            {items && (
                <div className={s['FaqList__List']}>
                    {items.map(({question, answer}, index) => (
                        <div className={s['FaqList__Item']} key={index}>
                            <ExpandableCard title={question} onOpen={handleFaqOpen}>
                                <div className={s['FaqList__Answer']}>
                                    <Wysiwyg wysiwyg={answer} />
                                </div>
                            </ExpandableCard>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default FaqList;
